:root {
  --text: #2b3044;
  --line: #bbc1e1;
  --line-active: #f16c20;
}
* {
  box-sizing: border-box;
}

body {
  font-family: work-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: #f5f0ed;
  min-height: 100%;
  overflow-x: hidden;
}
.header {
  display: flex;
  height: 100vh;
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 800ms ease 0s, transform 800ms ease 0s;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-style: normal;
}

.logo-header {
  height: 55px;
}

.logo-header:hover {
  color: #fff;
}

.aboutus {
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.works {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-aboutus {
  font-weight: 400;
  font-size: 1.4em;
  text-align: left;
}

.underline-animation {
  text-decoration: none;
  color: #fff;
  background: conic-gradient(
      from 135deg at 50% -10px,
      #f16c20 90deg,
      transparent 91deg
    )
    50% calc(-3px - 50%) / 100% 300% no-repeat;
  transition: 0.5s;
}
.underline-animation:hover {
  background-position: bottom;
  color: #fff;
}

.p-works {
  font-weight: 400;
  font-size: 2.5em;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}
.container-aboutus {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
}
.container-works {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
}

.works-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 50px;
}

.work-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 2.5rem;
  width: 100%;
  min-height: 400px;
  transition: 0.5s;
  scale: 1;
  background-color: #171717;
}

.works-cards > div {
  flex: 0 0 40%;
  margin: 10px;
  padding: 3rem;
}

.works-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.work-card:hover {
  z-index: 1000;
  background-color: #171717;
  scale: 1.01;
}
.works.blur-background > .container-works > *:not(.works-cards) {
  filter: blur(5px);
}

.works-cards .work-card.blurred {
  filter: blur(5px);
}
.work-text {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
}

.work-image {
    border-radius: 2.5rem !important;
    width: 100%;
    max-height: 300px;
    height: auto;
    object-fit: cover;
}

h3.work-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.work-title {
  font-weight: 400;
  text-align: left;
  font-style: bold;
  margin-bottom: 0rem;
}
.work-description {
  margin: 0px;
  font-weight: 300;
  font-size: 1em;
  text-align: left;
  color: #a7a4a29f;
}

span.work-category {
  padding: 5px 10px;
  background-color: #737373;
  border-radius: 8px;
}

.work-categories {
    gap: 0.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bg-dark {
  background-color: #0a0a0a !important;
  color: #fff;
  transition: background-color 400ms ease 0s, color 400ms ease 0s;
}

.bold {
  font-family: work-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.xbold {
  font-family: work-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}

.light {
  color: #fff !important;
}
.dark {
  color: #000 !important;
}

.bg-light {
  background-color: #f5f0ed;
  color: #000;
  transition: background-color 400ms ease 0s, color 400ms ease 0s;
}

.footer {
  width: 100%;
  display: flex;
  padding: 0px 50px 20px;
  font-size: 0.8em;
  justify-content: space-between;
}
.footer-social {
  flex: 1;
  display: block;
  text-align: right;
}

.footer-social a {
  font-size: 1rem;
  margin-right: 20px;
  color: #fff;
  transition: color 400ms ease 0s;
}
.icon {
  transition: 0.5s;
  z-index: 3;
}

a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

a:before {
  content: "";
  position: absolute;
  transition: 0.5s;
  z-index: 2;
}

.footer-link {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #f16c20;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
}

.footer-copyright {
  flex: 1;
}

.footer-copyright p {
  font-weight: 700;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #f16c20, #f16c20 50%, white 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 500ms ease;
}
.footer-copyright p:hover {
  background-position: 0 100%;
}

.footer-credits a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit;
  margin: 0 var(--spacing, 0px);
  transition: margin 0.25s;
}

/*Stroke length 491.278564453125 */
.footer-credits a svg {
  width: 105px;
  height: 40px;
  position: absolute;
  left: 51%;
  bottom: 0;
  transform: translate(-50%, 5px) translateZ(0);
  fill: none;
  stroke: var(--stroke, var(--line));
  stroke-linecap: round;
  stroke-width: 2px;
  stroke-dasharray: var(--offset, 108px) 496px;
  stroke-dashoffset: 5px;
  transition: stroke 0.25s ease var(--stroke-delay, 0s), stroke-dasharray 0.35s;
}
.footer-credits a:hover {
  --spacing: 8px;
  --stroke: var(--line-active);
  --stroke-delay: 0.1s;
  --offset: 490px;
}

.link {
  text-decoration: none;
  color: #fff;
}

.hero-contacts a {
  font-size: 1.5rem;
  color: var(--text);
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.hero-contacts a .fa-brands {
  transition: 0.5s;
}

.hero-contacts a:hover .fa-brands {
  transform: scale(1.1);
  transition: 0.5s;
}

.header .fa-arrow-down {
  opacity: 0.2;
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.2 !important;
    box-shadow: 0 0 0 0 rgba(16, 219, 255, 0.7);
  }

  70% {
    opacity: 0.7 !important;
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    opacity: 1 !important;
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 760px) {
  .works-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .container-works {
    margin-top: 50px;
  }
  
  .work-categories{
    align-items: center;
  }

  .works-cards > div {
    flex: 100%;
    margin: 10px;
    padding: 1.5rem;
  }

  .footer {
    flex-direction: row;
  }
  .footer-credits {
    display: none;
  }
}
